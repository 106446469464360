import { useState, useEffect, useRef, useContext } from "react";
import { Rnd } from "react-rnd";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, IconButton, Button } from "@mui/material";
import "./style.scss";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FileUpload from "../FileUpload/FileUpload";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningPromptModal from "../prompts/WarningPromptModal";
import {
  STEPPER_STEP_ADD_METADATA,
  STEPPER_STEP_MODIFY_IMAGE,
} from "../Root/constants";

import NewSlideRightArrowIcon from "../../Images/new-slide-right-icon.svg";
import NewSlideLeftArrowIcon from "../../Images/new-slide-left-icon.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { drawDiv, mousedrag } from "./DrawLayOut";
import {
  getDynamicLayoutHTML,
  getFileDetails,
  getHTMLResponse,
  intermediateSave,
} from "../../services/api";
import SideIcon from "./SideIcon";
import PDFViewer from "../PDFViewer/PDFViewer";
import { display, flexbox } from "@mui/system";
import PDFRemoteViewer from "../PDFViewer/PDFRemoteViewer";
import { toast } from "react-toastify";
import CustomCircularProgressBar from "../CustomCircularProgress";
import { Tooltip } from "@material-ui/core";
import {
  APPLICATION_TYPE_EDETAIL,
  EDETAIL_WIDTH,
  EMAILER_WIDTH,
} from "../ImageLayout/Constants";
import FileContext from "../../context/FileContext";

const fullFilePath =
  process.env.REACT_APP_API_ENDPOINT + "/middleware/downloadAttachments/";
function HTMLPreview(props) {
  const [savedbBoxJSON, setSavedbBoxJSON] = useState([]);
  const [isHTMLLoading, setHTMLLoading] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(0);
  const [isThresholdVisible, setIsThresholdVisible] = useState(false);
  const [thresholdErrorMessage, setThresholdErrorMessage] = useState("");
  const { customDrag } = props;
  const layoutBoxRef = useRef(null);
  const fileContext = useContext(FileContext);

  const [isPDFVisible, setPDFVisible] = useState(false);
  const containerRef = useRef(null);
  const [openWarnModal, setOpenWarnModal] = useState(false);
  const toastId = useRef(null);

  const selectionDiv = {
    width: 1,
    height: 1,
    left: 0,
    top: 0,
    pointerX: 0,
    pointerY: 0,
    timestamp: 0,
  };
  const [multiSelect, setMultiSelect] = useState(selectionDiv);
  const [multiSelectedComps, setMultiSelectedComps] = useState([]);
  const [drawLayout, setDrawLayout] = useState(true);
  const [dragContent, setDragContent] = useState(false);
  const [selectedDiv, setSelectedDiv] = useState(-1);
  const [dragOrResize, setDragOrReize] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [containerDimensions, setContainerDimensions] = useState({});

  const [pdfURL, setPDFURL] = useState("");
  const [grapeDom, setGrapeDom] = useState("");

  const [currentTab, setCurrentTab] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isCurrentTabLoading, setIsCurrentTabLoading] = useState(false);
  const [showMenuOrigin, setShowMenuOrigin] = useState("");
  const [layoutsFetched, setLayoutsFetched] = useState({
    sections: [],
    innerLayouts: [],
    columns: [],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [getLayoutsClicked, setGetLayoutsClicked] = useState(false);
  var sectionLK = 1;

  const myContainer = useRef(null);
  // const handleClickOpen = () => {
  //   setIsThresholdVisible(true);
  // };

  const validateSection = (section, templayouts, drawnIndexInAllLayouts) => {
    let retVal = true;
    if (templayouts.length === 1) return retVal;
    let tempSections = templayouts.filter((boxes) =>
      boxes.outline.includes("#ef9a9a")
    );
    tempSections.push(section);
    tempSections.sort((a, b) => parseFloat(a.top) - parseFloat(b.top));

    let drawnIndex = tempSections.findIndex((section) =>
      section.outline.includes("black")
    );
    if (drawnIndex === 0) {
      if (
        parseFloat(tempSections[drawnIndex].top) +
          parseFloat(tempSections[drawnIndex].height) >
        parseFloat(tempSections[drawnIndex + 1].top)
      ) {
        templayouts.splice(drawnIndexInAllLayouts, 1);
        if (!toast.isActive(toastId.current)) {
          toast.error("Overlapping outersections are not allowed!", {
            toastId: "files-search-error",
            pauseOnFocusLoss: false,
          });
        }
        retVal = false;
      }
    } else if (
      parseFloat(tempSections[drawnIndex].top) <
        parseFloat(tempSections[drawnIndex - 1].top) +
          parseFloat(tempSections[drawnIndex - 1].height) &&
      parseFloat(tempSections[drawnIndex].top) +
        parseFloat(tempSections[drawnIndex].height) >
        parseFloat(tempSections[drawnIndex + 1].top)
    ) {
      templayouts.splice(drawnIndexInAllLayouts, 1);
      if (!toast.isActive(toastId.current)) {
        toast.error("Overlapping outersections are not allowed!", {
          toastId: "files-search-error",
          pauseOnFocusLoss: false,
        });
      }
      retVal = false;
    } else if (
      parseFloat(tempSections[drawnIndex].top) >
        parseFloat(tempSections[drawnIndex - 1].top) &&
      parseFloat(tempSections[drawnIndex].top) +
        parseFloat(tempSections[drawnIndex].height) <
        parseFloat(tempSections[drawnIndex - 1].top) +
          parseFloat(tempSections[drawnIndex - 1].height)
    ) {
      templayouts.splice(drawnIndexInAllLayouts, 1);
      if (!toast.isActive(toastId.current)) {
        toast.error("Nested outer sections are not allowed!", {
          toastId: "files-search-error",
          pauseOnFocusLoss: false,
        });
      }
      retVal = false;
    }
    return retVal;
  };

  const showMenu = (e, origin) => {
    console.log("showMenu", e);
    if (!e) return;
    setShowMenuOrigin(origin);
    let x, y;
    if (e?.target) {
      e.preventDefault();
      if (selectedDiv < 0) return;
      // if (parseFloat(y) > 480) y = parseFloat(y) - 480;
      let selectedBox = props?.arrayOfBoxesDrawn[selectedDiv];
      if (selectedBox.drawn !== "true") return;
      x =
        parseFloat(
          parseFloat(selectedBox.left) + parseFloat(selectedBox.width)
        ) - 130;

      if (parseFloat(x) < 0) {
        //GAT-171
        // if user draws layout at extreem left side then this context menu is not visible as it will start at negative left position.
        // So making it 0
        x = 0;
      }
      x = x + "px";
      y = props?.arrayOfBoxesDrawn[selectedDiv].top;
    } else {
      x = e?.x + "px";
      y = e?.y + "px";
      if (parseFloat(x) < 0) {
        //GAT-171
        // if user draws layout at extreem left side then this context menu is not visible as it will start at negative left position.
        // So making it 0
        x = 0;
      }
    }
    setMenuPosition({ top: parseFloat(y), left: parseFloat(x) });
    setMenuVisible(true);
  };
  const hideMenu = (origin) => {
    if (showMenuOrigin === "onStopDraw" && origin !== "delete") return;
    setMenuVisible(false);
    setShowMenuOrigin("");
  };
  const addType = (type, origin) => {
    console.log("type", type);
    let tempLayouts = JSON.parse(JSON.stringify(props.arrayOfBoxesDrawn));
    console.log("selectedDiv", selectedDiv);
    let layout =
      selectedDiv > -1
        ? tempLayouts[selectedDiv]
        : tempLayouts[tempLayouts.length - 1];

    let index = selectedDiv > -1 ? selectedDiv : tempLayouts.length - 1;
    // if (layout?.drawn !== "true" && origin !== "drawn") {
    //   return;
    // }
    setMenuVisible(false);
    switch (type) {
      case "SECTION":
        if (validateSection(layout, tempLayouts, index)) {
          layout.outline = "#ef9a9a dashed 1px";
          layout.width = "600px";
          layout.left = 1;
          layout.zIndex = 150;
        }
        break;

      case "COLUMN":
        layout.outline = "#24f52e dashed 1px";
        layout.zIndex = 350;

        break;
    }
    layout.drawn = "true";
    props.setArrayOfBoxesDrawn(tempLayouts);
    props.arrayOfBoxesDrawnRef.current = tempLayouts;
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };
  const handleMenuOpen = (e) => {
    setMenuOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const clearAllLayouts = () => {
    setShowMenuOrigin("");

    props.setArrayOfBoxesDrawn([]);
    setLayoutsFetched({
      sections: [],
      innerLayouts: [],
      columns: [],
    });
    props.arrayOfBoxesDrawnRef.current = [];
    props.setIsGJSLoading(true);
    props.setIsLayoutGenerated(false);
    props.setClearAllLayoutClicked(true);

    // props.setDisableGenerateLayout(true);
    setSelectedDiv(-1);
  };
  const handleClose = () => {
    setIsThresholdVisible(false);
  };

  const handleThresholdInput = (e) => {
    const value = e.target.value;
    setThresholdValue(value);
    if (value < 1 || value > 100) {
      setThresholdErrorMessage("Please enter value between 0 to 100");
    } else {
      setThresholdErrorMessage("");
    }
  };

  let showSelection = false;
  let Xstart;
  let Ystart;

  const generateSection = () => {
    //props.html;
    let blocks = document.getElementsByClassName("page")[0].childNodes;
    let sections = document.querySelectorAll(".test");
    let tempLayouts = JSON.parse(JSON.stringify(props.arrayOfBoxesDrawn));
    //sections.sort((a, b) => a.top - b.top);

    for (let i = 0; i < blocks.length; i++) {
      for (let j = 0; j < sections.length; j++) {
        if (
          sections[j].getBoundingClientRect().top - 10 <=
            blocks[i].getBoundingClientRect().top &&
          sections[j].getBoundingClientRect().bottom + 5 >=
            blocks[i].getBoundingClientRect().bottom &&
          sections[j].getBoundingClientRect().left - 10 <=
            blocks[i].getBoundingClientRect().left &&
          sections[j].getBoundingClientRect().right + 10 >=
            blocks[i].getBoundingClientRect().right
        ) {
          blocks[i].setAttribute("name", `section${j}`);
          blocks[i].setAttribute("draggable", true);
          blocks[i].setAttribute("name", `section${j}`);

          if (blocks[i].tagName !== "IMG")
            blocks[i].setAttribute("data-gjs-type", "text");
          else blocks[i].setAttribute("data-gjs-type", "image");

          blocks[i].addEventListener("dragstart", (e, j) => customDrag(e, j));
        } else {
        }
      }
    }
    // props.setArrayOfBoxesDrawn(tempLayouts);
  };

  const formArrayOfContainers = (
    array,
    i,
    color,
    temparrayOfDimenions,
    ctaBoxes,
    zIndex,
    listsContainer
  ) => {
    let leftC = color === "#24f52e" ? "x_1_column" : "x_1";
    let rightC = color === "#24f52e" ? "x_2_column" : "x_2";
    let topC = color === "#24f52e" ? "y_1_column" : "y_1";
    let bottomC = color === "#24f52e" ? "y_2_column" : "y_2";

    let obj = {};
    // ;
    let newLeft = "";
    let newRight = "";
    let newTop = "";
    let newBottom = "";
    if (containerDimensions.desiredHeight) {
      newLeft =
        (parseFloat(array[i][leftC]) * containerDimensions.desiredWidth) /
        containerDimensions.currentContainerWidth;
      newRight =
        (parseFloat(array[i][rightC]) * containerDimensions.desiredWidth) /
        containerDimensions.currentContainerWidth;
      newTop =
        (parseFloat(array[i][topC]) * containerDimensions.desiredHeight) /
        containerDimensions.currentContainerHeight;
      newBottom =
        (parseFloat(array[i][bottomC]) * containerDimensions.desiredHeight) /
        containerDimensions.currentContainerHeight;
    } else {
      newLeft = array[i][leftC];
      newRight = array[i][rightC];
      newTop = array[i][topC];
      newBottom = array[i][bottomC];
    }
    if (zIndex === 100) obj.left = 1;
    else obj.left = newLeft - (color === "#24f52e" ? 3 : 2);

    let right = newRight;
    let originalBoxWidth = newRight - newLeft;
    obj.top = newTop;
    let bottom = newBottom;

    if (array[i].blocktype === "LAYOUT_LIST") {
      listsContainer.push(obj);
    }
    if (ctaBoxes?.length) {
      if (
        ctaBoxes.filter(
          (ctaBox) =>
            newLeft >= parseFloat(ctaBox.left) &&
            newTop >= parseFloat(ctaBox.top) &&
            newRight <= parseFloat(ctaBox.right) &&
            newBottom <= parseFloat(ctaBox.bottom)
        ).length
      )
        return;
    }
    obj.right = right + "px";
    obj.bottom = bottom + "px";
    // obj.width = right - obj.left + "px";
    obj.width =
      (color === "#ef9a9a" ? right - obj.left - 2 : right - obj.left) + "px";
    obj.height = bottom - obj.top + "px";
    obj.left = (color === "#ef9a9a" ? 1 : obj.left) + "px";
    obj.top = obj.top + "px";
    obj.outline = `1px dashed ${color}`;
    obj.position = "absolute";
    temparrayOfDimenions.push(obj);
    obj.zIndex = zIndex + i;
    obj.originalBoxWidth = originalBoxWidth + "px";
    obj.blocktype = array[i]["blocktype"] || "";
    // obj.zoom = "27.9%";
    if (color === "#ef9a9a" && i === 0) {
      obj.borderTop = "1px dashed #ef9a9a";
    }
  };
  const getColumnLayouts = (redAndYellowLayouts, data, origin) => {
    let temparrayOfDimenionsColumn = [];

    let columns = data?.columns_metadata;
    const multipleColumns = [];
    columns.forEach((obj, index) => {
      let parent = "notSame";

      if (index > 0 && columns[index - 1].Section === obj.Section) {
        parent = "same";
        multipleColumns[index - 1].parent = "same";
      }
      multipleColumns.push({
        ...obj,
        x_1_column: obj.x_1_column - 2,
        x_2_column: obj.x_2_column + 2,
        parent: parent,
      });
    });
    columns = multipleColumns.filter((column) => column.parent === "same");
    let columnsLength = columns?.length;

    if (!columnsLength) return;

    for (let i = 0; i < columnsLength; i++) {
      formArrayOfContainers(
        columns,
        i,
        "#24f52e",
        temparrayOfDimenionsColumn,
        "",
        200
      );
    }
    if (origin !== "useEffect")
      props.setArrayOfBoxesDrawn([
        ...redAndYellowLayouts,
        ...temparrayOfDimenionsColumn,
      ]);
    setLayoutsFetched({
      sections: redAndYellowLayouts.filter(
        (section) => !section.outline.includes("#00A5D6")
      ),
      innerLayouts: redAndYellowLayouts.filter((section) =>
        section.outline.includes("#00A5D6")
      ),
      columns: [...temparrayOfDimenionsColumn],
    });
  };

  const getUserGeneratedHTML = () => {
    if (!props.usrGenHtMLRef.current?.editor?.getComponents()) return false;
    const htmlString = props.usrGenHtMLRef.current?.editor?.getHtml();
    const cssContent = props.usrGenHtMLRef.current?.editor?.getCss();
    var divElement = HtMLStringToDOMElement(htmlString);
    const cssStyles = props.cssStringToCSSStyles(cssContent);
    const HTMLWithStyle = props.replaceIdsWithStyles(divElement, cssStyles);
    // Now, divElement contains

    // const blob = new Blob([`${divElement.outerHTML}`], {
    //   type: "text/html",
    // });

    return divElement.outerHTML;
  };

  const getFiileById = (id, parentId) => {
    props.setIsGJSLoading(true);
    props.setUploadedFileDetails(null);
    getFileDetails(id, parentId)
      .then((res) => {
        props.setArrayOfBoxesDrawn([]);
        props.arrayOfBoxesDrawnRef.current = [];
        props.setIsGJSLoading(true);
        props.setIsLayoutGenerated(false);
        props.setClearAllLayoutClicked(false);
        // props.setDisableGenerateLayout(true);
        props.setIsPublished(
          res.data.file_conversion_pages[0].message.step ===
            STEPPER_STEP_ADD_METADATA
        );
        props.setUploadedFileDetails(res.data);
        props.setIsGJSLoading(false);
      })
      .catch((err) => {
        props.setIsGJSLoading(false);
      });
  };

  const getNextPrevHTML = (id) => {
    if (!props.arrayOfBoxesDrawn?.length || props.disableGenerateLayout) {
      const parentId =
        props.fileDetails?.file_conversion_pages[0]?.conversion_id;
      getFiileById(id, parentId);
    } else {
      const htmlString = getUserGeneratedHTML();
      if (!htmlString) return;
      // console.log("props.filedEtails", props.fileDetails);
      let body = {
        htmlContent: htmlString, //rhs sectionsLayot
        layouts: [...props.arrayOfBoxesDrawnRef.current],
        uuid: props?.fileDetails?.file_conversion_pages[0]?.page_uuid,
        id: props.fileDetails?.file_conversion_pages[0]?.id,
        parent_id: props.fileDetails?.file_conversion_pages[0]?.conversion_id,
      };

      // if (props.innerLayoutsRef.current.elements_metadata) {
      //   body.layouts.push({
      //     innerLayouts: props.innerLayoutsRef.current.elements_metadata,
      //   });
      // }
      props.setIsGJSLoading(true);
      intermediateSave(body)
        .then((response) => {
          const parentId =
            props.fileDetails?.file_conversion_pages[0]?.conversion_id;
          getFiileById(id, parentId);
        })
        .catch((error) => {
          props.setIsGJSLoading(false);
        });
    }
  };

  const goNextTab = () => {
    let id = props.fileDetails?.nextId;
    if (id) {
      getNextPrevHTML(id);
    }
  };

  const goPrevTab = () => {
    let id = props.fileDetails?.previousId;
    if (id) {
      getNextPrevHTML(id);
    }
  };

  const getInnerLayouts = (temparrayOfDimenions, data, origin) => {
    let temparrayOfDimenionsInner = [];

    let innerLaayoutsLength = data?.elements_metadata?.length;
    if (!innerLaayoutsLength) return;
    let ctaBoxes = props.arrayOfBoxesDrawn.filter((box) =>
      box.outline.includes("#ac0bf1")
    );
    let listsContainer = [];
    for (let i = 0; i < innerLaayoutsLength; i++) {
      formArrayOfContainers(
        data.elements_metadata,
        i,
        "#00A5D6",
        temparrayOfDimenionsInner,
        ctaBoxes,
        300,
        listsContainer
      );
    }

    for (let i = 0; i < listsContainer.length; i++) {
      for (let j = 0; j < temparrayOfDimenionsInner.length; j++)
        if (
          parseFloat(listsContainer[i].top) <
            parseFloat(temparrayOfDimenionsInner[j].top) &&
          parseFloat(listsContainer[i].bottom) >
            parseFloat(temparrayOfDimenionsInner[j].bottom) &&
          parseFloat(listsContainer[i].left) <
            parseFloat(temparrayOfDimenionsInner[j].left) &&
          parseFloat(listsContainer[i].right) >
            parseFloat(temparrayOfDimenionsInner[j].right)
        ) {
          temparrayOfDimenionsInner[j].zIndex =
            listsContainer[i].zIndex + j + 1;
        }
    }

    let redAndYellowLayouts =
      fileContext.file.application_type === APPLICATION_TYPE_EDETAIL
        ? temparrayOfDimenionsInner
        : [...temparrayOfDimenions, ...temparrayOfDimenionsInner];
    if (origin !== "useEffect") {
      props.setArrayOfBoxesDrawn(redAndYellowLayouts);
    }
    if (fileContext.file.application_type !== APPLICATION_TYPE_EDETAIL)
      getColumnLayouts(redAndYellowLayouts, data, origin);
  };

  // const clearOuterLayouts = () => {
  //   let redArray = props.arrayOfBoxesDrawn.filter((box) =>
  //     box?.outline?.includes("#ef9a9a")
  //   );
  //   if (!redArray.length) return;
  //   props.setArrayOfBoxesDrawn(
  //     props.arrayOfBoxesDrawn.filter((box) => !box.outline.includes("#ef9a9a"))
  //   );
  //   props.setDisableGenerateLayout(true);

  //   toast.error("Fetch all layouts to enable GENERATE LAYOUT", {
  //     toastId: "button-disable-error",
  //   });
  // };
  const clearInnerLayouts = () => {
    let yellowArray = props.arrayOfBoxesDrawn.filter((box) =>
      box?.outline?.includes("#00A5D6")
    );
    if (!yellowArray.length) return;
    props.setArrayOfBoxesDrawn(
      props.arrayOfBoxesDrawn.filter((box) => !box.outline.includes("#00A5D6"))
    );
    // props.setDisableGenerateLayout(true);

    toast.error("Fetch all layouts to enable GENERATE LAYOUT", {
      toastId: "button-disable-error",
    });
  };
  const clearColumnLayouts = () => {
    let columnArray = props.arrayOfBoxesDrawn.filter((box) =>
      box.outline.includes("#24f52e")
    );
    if (!columnArray.length) return;
    props.setArrayOfBoxesDrawn(
      props.arrayOfBoxesDrawn.filter((box) => !box.outline.includes("#24f52e"))
    );
    // props.setDisableGenerateLayout(true);
    toast.error("Fetch all layouts to enable GENERATE LAYOUT", {
      toastId: "button-disable1-error",
    });
  };
  const getLayout = (origin) => {
    // setIsThresholdVisible(false);
    //  ;
    let newArray = [];
    let scaleFactor = 1;
    if (props.imageDimensions.modifiedWidth) {
      scaleFactor =
        props.imageDimensions.modifiedWidth /
        props.imageDimensions.originalWidth;
    }
    props.arrayOfBoxesDrawnImage?.forEach((element) =>
      newArray.push({
        x_1: parseFloat(element.left) / scaleFactor,
        y_1: parseFloat(element.top) / scaleFactor,
        x_2: parseFloat(element.right) / scaleFactor,
        y_2: parseFloat(element.bottom) / scaleFactor,
        blocktype: element.blocktype || "",
      })
    );
    const body = {
      s3_image_url: props.fileDetails?.file_conversion_pages[0]?.image_path,
      bbox_json: newArray.length ? newArray : savedbBoxJSON,
      height_threshold: 1, // parseInt(thresholdValue),
      id: props.fileDetails?.file_conversion_pages[0].id,
      parent_id: props.fileDetails?.file_conversion_pages[0]?.conversion_id,
    };
    setHTMLLoading(true);

    getDynamicLayoutHTML(body)
      .then((apiResp) => {
        setHTMLLoading(false);
        let data = apiResp.data;
        props.setCooridinatesFromAPI(data);

        props.innerLayoutsRef.current = data;
        let temparrayOfDimenions = [];
        let containersLength = data.sections_metadata.length;
        let ctaContainersLength = 0;
        props.setClearAllLayoutClicked(false);

        // data.yolo_metadata.length;

        for (let i = 0; i < containersLength; i++) {
          // let innerLaayoutsLength = data.elements_metadata.blocks.length;
          // let innerLaayoutsLength = data.elements_metadata.length;

          formArrayOfContainers(
            data.sections_metadata,
            i,
            "#ef9a9a",
            temparrayOfDimenions,
            "",
            100
          );
        }
        for (let i = 0; i < ctaContainersLength; i++) {
          // let innerLaayoutsLength = data.elements_metadata.blocks.length;
          formArrayOfContainers(
            data.yolo_metadata,
            i,
            "#ac0bf1",
            temparrayOfDimenions
          );
        }

        //listsContainer
        // props.setArrayOfBoxesDrawn(temparrayOfDimenions);
        getInnerLayouts(temparrayOfDimenions, data, origin);
        // props.setDisableGenerateLayout(false);

        props.setGenerateLayout(false);
        props.genLayoutRef.current = false;
      })
      .catch((err) => {
        setHTMLLoading(false);
      });
  };
  const fetchInnerLayouts = () => {
    let layoutsWithoutInner = props.arrayOfBoxesDrawn.filter(
      (box) => !box?.outline?.includes("#00A5D6")
    );
    props.setArrayOfBoxesDrawn([
      ...layoutsWithoutInner,
      ...layoutsFetched.innerLayouts,
    ]);
  };
  // const fetchOuterLayouts = () => {
  //   let layoutsWithoutOuter = props.arrayOfBoxesDrawn.filter(
  //     (box) => !box?.outline?.includes("#ef9a9a")
  //   );
  //   props.setArrayOfBoxesDrawn([
  //     ...layoutsWithoutOuter,
  //     ...layoutsFetched.sections,
  //   ]);
  // };
  const fetchColumnLayouts = () => {
    // clearColumnLayouts();
    let layoutsWithoutColumn = props.arrayOfBoxesDrawn.filter(
      (box) => !box?.outline?.includes("#24f52e")
    );
    props.setArrayOfBoxesDrawn([
      ...layoutsWithoutColumn,
      ...layoutsFetched.columns,
    ]);
  };

  function arraysEqual(arr1, arr2) {
    if (arr1?.length !== arr2?.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        console.log(i, "indexWhere arrays are not equal");
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (props.arrayOfBoxesDrawn.length === 0) {
      props.setGenerateLayout(true);
      props.genLayoutRef.current = true;
    } else if (!props.fileDetails.user_html_file_path || !props.isGJSLoading) {
      props.setGenerateLayout(false);
      if (props)
        console.log(
          " when arrayOfBoxesDrawn.length !== 0 editor?.setComponents"
        );
      props.genLayoutRef.current = false;
    }
    // add autosave code here
    if (!props.clearAllLayoutClicked && !props.arrayOfBoxesDrawn.length) return;
    if (
      arraysEqual(
        props.fileDetails?.file_conversion_pages[0]?.layouts,
        props.arrayOfBoxesDrawn
      )
    )
      return;

    const htmlString = props.usrGenHtMLRef.current?.editor
      ? getUserGeneratedHTML()
      : "";
    let body = {
      htmlContent: htmlString, //rhs sectionsLayot
      layouts: [...props.arrayOfBoxesDrawn],
      // uuid: props?.fileDetails?.file_conversion_pages[0]?.page_uuid,
      id: props.fileDetails?.file_conversion_pages[0]?.id,
      parent_id: props.fileDetails?.file_conversion_pages[0]?.conversion_id,
    };
    intermediateSave(body)
      .then((response) => {
        console.log("saved successfully");
        props.setIsGJSLoading(false);
      })
      .catch((error) => {
        props.setIsGJSLoading(false);
      });
  }, [props.arrayOfBoxesDrawn]);
  const HtMLStringToDOMElement = (htmlString) => {
    // Create a DOMParser

    const parser = new DOMParser();

    // Parse the HTML string into a DOM element
    const doc = parser.parseFromString(htmlString, "text/html");
    // Extract the html element from the parsed document

    return doc.querySelector("html");
  };
  //t -m "Merged"
  const reduceImageAndFontSize = (htmlResp) => {
    let edetail = true;
    let componentWidth = "";
    if (props.fileDetails?.application_type === APPLICATION_TYPE_EDETAIL) {
      componentWidth = EDETAIL_WIDTH;
    } else {
      componentWidth = EMAILER_WIDTH;
    }
    let htmlDOMEl = HtMLStringToDOMElement(htmlResp);
    let containerDiv = htmlDOMEl.childNodes[1].childNodes[0];
    {
      if (containerDiv && parseFloat(containerDiv.style.width) !== 600) {
        //  console.log("containrdimensionset");
        var currentContainerWidth = parseFloat(containerDiv.style.width);
        var currentContainerHeight = parseFloat(containerDiv.style.height);
        var desiredWidth = 600;
        var desiredHeight =
          (currentContainerHeight * desiredWidth) / currentContainerWidth;
        setContainerDimensions({
          currentContainerWidth: currentContainerWidth,
          currentContainerHeight: currentContainerHeight,
          desiredWidth: desiredWidth,
          desiredHeight: desiredHeight,
        });
        var scaleFactor = desiredWidth / currentContainerWidth;
        containerDiv.style.width = desiredWidth + "px";
        containerDiv.style.height = desiredHeight + "px";
        var elementsWithPxValues =
          containerDiv.querySelectorAll('*[style*="px"]');
        for (var i = 0; i < elementsWithPxValues.length; i++) {
          var element = elementsWithPxValues[i];
          console.log("element.tagName", element.tagName);
          var newRelativeValueFont =
            parseFloat(element.style.fontSize) * scaleFactor;
          var newRelativeValueTop = parseFloat(element.style.top) * scaleFactor;
          var newRelativeValueLeft =
            parseFloat(element.style.left) * scaleFactor;

          // Update the element with the new value
          element.style.fontSize = newRelativeValueFont + "px";
          element.style.top = newRelativeValueTop + "px";
          element.style.left = newRelativeValueLeft + "px";
          // element.style.textWrap = "noWrap";

          // if (element.parentElement.tagName === "TD") {
          //   ;
          // element.style.top = newRelativeValueTop + 15 + "px";
          // }

          if (
            element.tagName === "TABLE" ||
            element.tagName === "TD" ||
            element.tagName === "TH"
          ) {
            let tableHeightAdjust =
              (parseFloat(element.style.height) * 8) / 123;
            console.log(
              tableHeightAdjust,
              element.tagName,
              "tableHeightAdjust "
            );
            element.style.width =
              parseFloat(element.style.width) * scaleFactor + "px";
            element.style.height =
              (parseFloat(element.style.height) - 8) * scaleFactor + "px";
          }
          if (element.tagName === "IMG" || element.tagName === "A") {
            if (element.tagName === "A") {
              let height = parseFloat(element.style.height) * scaleFactor;
              // element.style.height =
              //   parseFloat(element.style.height) * scaleFactor + "px";
              element.style.display = "inline-block";
              element.style.textAlign = "center";
              element.style.padding = height / 2.5 + "px";
              element.style.height = "";
            }
            //  console.log(element.style.width * scaleFactor, "newWidth");
            element.style.width =
              parseFloat(element.style.width) * scaleFactor + "px";
            // element.setAttribute("onload", () => onEachIageLoad(divs - i));
          }
        }
      }
    }
    props.setHtml(htmlDOMEl.outerHTML);
    props.htmlRef.current = htmlDOMEl.outerHTML;
  };
  useEffect(() => {
    let pSteps = props.prevStep;
    if (props.fileDetails) {
      setTotalPages(props?.fileDetails?.num_pages);
      const body = {
        s3_html_url:
          props.fileDetails?.file_conversion_pages[0]?.html_file_path,
      };
      setHTMLLoading(true);
      const fetchData = async () => {
        try {
          // First Axios call
          const firstResponse = await getHTMLResponse(body);
          reduceImageAndFontSize(firstResponse.data);

          if (props.arrayOfBoxesDrawn.length) {
            setTimeout(() => {
              var elmnt = document.getElementById("mainCanvasContainerWrapper");
              var elmnt2 = document.querySelector(".gjs-frame-wrapper");
              if (elmnt?.scrollHeight) {
                const height = elmnt.scrollHeight + 45; // in HTML preview there is extra div with height 45px. So to match that adding this height
                // console.log("height is " + height);
                elmnt2.style = `height: ${height}px !important`;
              }
            }, 500);
          }

          if (pSteps === STEPPER_STEP_MODIFY_IMAGE) {
            props.setIsGJSLoading(false);
          }

          // Second Axios call
          // const secondResponse =
          //   pSteps === 2
          //     ? { data: props.rhsHtmlForExport }
          //     : await getHTMLResponse({
          //         s3_html_url: props.fileDetails.user_html_file_path,
          //       });
          // props.setRhsHtmlForExport(secondResponse.data);
          // props.rhsHtmlForExportRef.current = secondResponse.data;
          // props.setGenerateLayout(true);
          // props.genLayoutRef.current = true;
          setHTMLLoading(false);
        } catch (error) {
          console.log("Error in one of the Axios calls:", error);
          setHTMLLoading(false);
          props.setIsGJSLoading(false);
        }
      };
      fetchData();
      const getHTMLDetails = async () => {
        // props.setIsGJSLoading(true);
        try {
          const pageId = props.fileDetails?.file_conversion_pages[0]?.id;
          const parentId =
            props.fileDetails?.file_conversion_pages[0]?.conversion_id;
          let fileDetails = await getFileDetails(pageId, parentId);
          fileDetails = fileDetails?.data;
          setPDFURL(fullFilePath + props.fileDetails?.pdf_file_path);
          setSavedbBoxJSON(
            fileDetails?.file_conversion_pages[0]?.user_drawn_layouts
          );
          let arrayTemp =
            fileDetails?.file_conversion_pages[0]?.layouts.filter(
              (layout) => !layout.hasOwnProperty("innerLayouts")
            ) || [];
          props.setArrayOfBoxesDrawn(arrayTemp);
          props.arrayOfBoxesDrawnRef.current = arrayTemp;
          if (
            fileDetails?.file_conversion_pages[0]?.layouts[
              fileDetails?.file_conversion_pages[0]?.layouts.length - 1
            ]?.innerLayouts
          ) {
            props.setCooridinatesFromAPI({
              ...props.cooridinatesFromAPI,
              elements_metadata:
                fileDetails?.file_conversion_pages[0]?.layouts[
                  fileDetails?.file_conversion_pages[0]?.layouts?.length - 1
                ].innerLayouts,
            });
            props.innerLayoutsRef.current = {
              ...props.cooridinatesFromAPI,
              elements_metadata:
                fileDetails?.file_conversion_pages[0]?.layouts[
                  fileDetails?.file_conversion_pages[0]?.layouts?.length - 1
                ]?.innerLayouts,
            };
          }
          debugger;
          var secondResponse = "";
          if (fileDetails?.file_conversion_pages[0]?.user_html_file_path) {
            secondResponse = await getHTMLResponse({
              s3_html_url:
                fileDetails?.file_conversion_pages[0]?.user_html_file_path,
            });
          } else secondResponse = { data: `<body></body>` };
          if (secondResponse.data) {
            props.setRhsHtmlForExport(secondResponse.data);
            props.rhsHtmlForExportRef.current = secondResponse.data;
            props.setGenerateLayout(true);
            props.genLayoutRef.current = true;
            console.log("second response.data editor?.setComponents");
          }

          setHTMLLoading(false);
          setGrapeDom(document.querySelector(".gjs-frame"));
          // props.setDisableGenerateLayout(false);
        } catch (error) {
          props.setIsGJSLoading(false);
          console.log("Error in one of the Axios calls:", error);
          setHTMLLoading(false);
        }
      };
      if (pSteps !== STEPPER_STEP_MODIFY_IMAGE) {
        getHTMLDetails();
      }
      // getHTMLDetails();
      if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL)
        setDrawLayout(false);
    }
  }, [props.fileDetails]);

  useEffect(() => {
    //fetch the layouts on refresh and save in local
    //for new revise flow"
    if (savedbBoxJSON?.length && containerDimensions?.desiredHeight) {
      console.log("useEffect");
      getLayout("useEffect");
    }
  }, [savedbBoxJSON, containerDimensions]);
  useEffect(() => {
    props.setIsLayoutGenerated(false);
    setTimeout(() => {
      var elmnt2 = document.querySelector(".gjs-frame");
      var elmnt = document.getElementById("mainCanvasContainerWrapper");
      var elmnt2 = document.querySelector(".gjs-frame-wrapper");
      if (elmnt?.scrollHeight) {
        const height = elmnt.scrollHeight + 45; // in HTML preview there is extra div with height 45px. So to match that adding this height;
        if (elmnt2) elmnt2.style = `height: ${height}px !important`;
      }
      // elmnt2.style.setProperty('height', elmnt.scrollHeight+'px', '!important');
    }, 500);
    props?.file?.file
      ? setPDFURL(URL.createObjectURL(props.file.file))
      : setPDFURL(fullFilePath + props.fileDetails?.pdf_file_path);
    setShowMenuOrigin("");
  }, []);
  useEffect(() => {
    if (
      props.html &&
      !props.arrayOfBoxesDrawn.length &&
      !props.fileDetails.user_html_file_path
    ) {
      props.setIsGJSLoading(false);
    }
  }, [props.html]);

  const updateResizedDivs = (object) => {
    let tempArrayOfBoxesDrawn = JSON.parse(
      JSON.stringify(props.arrayOfBoxesDrawn)
    );
    let obj =
      object.origin === "resizeStop"
        ? {
            height: object.height + "px",
            width: object.width + "px",
            left: object.left + "px",
            top: object.top + "px",
          }
        : {
            left: object.x + "px",
            top: object.y + "px",
          };

    debugger;
    tempArrayOfBoxesDrawn[object.index] = {
      ...tempArrayOfBoxesDrawn[object.index],
      ...obj,
    };
    for (let i = 0; i < tempArrayOfBoxesDrawn?.length; i++) {
      if (
        i !== object.index &&
        parseFloat(tempArrayOfBoxesDrawn[object.index].top) <
          parseFloat(tempArrayOfBoxesDrawn[i].top) &&
        parseFloat(tempArrayOfBoxesDrawn[object.index].left) <
          parseFloat(tempArrayOfBoxesDrawn[i].left) &&
        parseFloat(tempArrayOfBoxesDrawn[object.index].width) >
          parseFloat(tempArrayOfBoxesDrawn[i].width) &&
        parseFloat(tempArrayOfBoxesDrawn[object.index].height) >
          parseFloat(tempArrayOfBoxesDrawn[i].height)
      ) {
        let newIndex = tempArrayOfBoxesDrawn[object.index]?.zIndex;
        tempArrayOfBoxesDrawn[object.index].zIndex =
          tempArrayOfBoxesDrawn[i].zIndex;
        tempArrayOfBoxesDrawn[i].zIndex = newIndex;
      }
    }
    props.setArrayOfBoxesDrawn(tempArrayOfBoxesDrawn);
    props.arrayOfBoxesDrawn.current = tempArrayOfBoxesDrawn;
  };
  const deleteSelectedDiv = () => {
    let tempArrayOfBoxesDrawn = props.arrayOfBoxesDrawn.filter(
      (item, index) => index !== selectedDiv
    );
    setSelectedDiv(-1);

    props.setArrayOfBoxesDrawn(tempArrayOfBoxesDrawn);
    props.arrayOfBoxesDrawn.current = tempArrayOfBoxesDrawn;
    if (!tempArrayOfBoxesDrawn.length) props.setClearAllLayoutClicked(true);
  };

  const onScroll = () => {
    var elmnt = document.getElementById("mainCanvasContainerWrapper");
    var elmnt3 = document.querySelector(".gjs-cv-canvas__frames");
    var elmnt2 = document.querySelector(".gjs-frame");
    elmnt2.contentWindow.scrollTo(0, elmnt.scrollTop);
    elmnt3.scrollTop = elmnt.scrollTop;
  };

  const scrollTop = () => {
    var elmnt = document.getElementById("mainCanvasContainerWrapper");
    var elmnt2 = document.querySelector(".gjs-frame");
    elmnt.scrollTo(0, 0);
  };

  const goBack = () => {
    fileContext.selectFile(props.fileDetails);
    props.setActiveStep(1);
  };

  if (isHTMLLoading) {
    return (
      <div className="preview">
        <div className="fileLoadProgress">
          <Box sx={{ display: "flex" }}>
            <CustomCircularProgressBar />
          </Box>
        </div>
      </div>
    );
  }

  const disableGenerateLayout = () => {
    let enabled = true;

    for (let layouts in layoutsFetched) {
      let layout = layoutsFetched[layouts];
      let colorCode;
      if (layout.length) {
        colorCode = layout[0].outline.match(/#[0-9a-fA-F]{6}/);
        enabled = props.arrayOfBoxesDrawn.filter((box) =>
          box.outline.includes(colorCode[0])
        ).length;
      }
      if (!enabled) break;
    }
    props.setDisableGenerateLayout(!enabled);
    return !enabled;
  };

  return (
    <div
      className="preview"
      // onContextMenu={showMenu}
      onClick={(e) => {
        // console.log("clicked hideMenu 598");
        e.preventDefault();
        e.stopPropagation();

        hideMenu();
      }}
    >
      <form className="formClass extractFont">
        Extracted HTML file
        {/* <button onClick={() => setPDFVisible(!isPDFVisible)}>Show PDF</button> */}
        <FormGroup>
          <FormControlLabel
            onClick={() => setPDFVisible(!isPDFVisible)}
            control={<Switch checked={isPDFVisible} />}
            label="Show PDF"
          />
        </FormGroup>
      </form>

      <PDFViewer
        fileURL={pdfURL}
        style={{ display: isPDFVisible ? "block" : "none" }}
        isPDFVisible={isPDFVisible}
      />

      <div
        style={{
          display: isPDFVisible ? "none" : "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div className="sidebarDiv">
          <div
            className="IconDiv"
            style={{ background: drawLayout ? "aliceblue" : "" }}
          >
            <IconButton
              onClick={() => {
                generateSection();
                setSelectedDiv(-1);
                // props.setGenerateLayout(true);
                // props.genLayoutRef.current = true;
                // props.setGenLayoutClicked(true);
                // console.log("clicked drawLayout sidebar ");
                //console.log("clicked drawLayout sidebar ");
                setDrawLayout(true);
              }}
              size="small"
              color="inherit"
              disabled={
                fileContext.file.application_type === APPLICATION_TYPE_EDETAIL
              }
            >
              <img src={require("../../Images/draw.png")} alt="logo" />
              <div className="sideLabel">Draw Layout</div>
            </IconButton>
          </div>
          <div
            className="IconDiv"
            style={{
              background: !drawLayout && dragOrResize ? "aliceblue" : "",
            }}
          >
            <IconButton
              onClick={() => {
                //console.log("clicked dragOrResize sidebar ");
                setDragOrReize(true);
                setDrawLayout(false);
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/resize.png")} alt="logo" />
              <div className="sideLabel">Resize</div>
            </IconButton>
          </div>
          <div
            className="IconDiv"
            style={{
              background: !drawLayout && !dragOrResize ? "aliceblue" : "",
            }}
          >
            <IconButton
              onClick={() => {
                //console.log("clicked dragContent sidebar ");

                setDrawLayout(false);
                setDragOrReize(false);
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/drag.png")} alt="logo" />
              <div className="sideLabel">Drag</div>
            </IconButton>
          </div>
          <div className="IconDiv">
            <IconButton
              onClick={(e) => {
                // console.log("clicked getInnerLayout");
                handleMenuOpen(e);
                setGetLayoutsClicked(true);
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/innerLayout.png")} alt="logo" />
              <div className="sideLabel">Get Layouts</div>
            </IconButton>
          </div>
          <div className="IconDiv">
            <IconButton
              onClick={() => {
                //console.log("clicked DeleteLayout sidebar ");
                deleteSelectedDiv();
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/delete.png")} alt="logo" />
              <div className="sideLabel">Delete</div>
            </IconButton>
          </div>
          <div className="IconDiv">
            <IconButton
              onClick={(e) => {
                // console.log("clicked ClearScreen sidebar");
                if (!props.arrayOfBoxesDrawn.length) return;

                hideMenu();
                handleMenuOpen(e);
                setGetLayoutsClicked(false);

                // props.setUploadedFileDetails({
                //   ...props.fileDetails,
                //   user_html_file_path: "",
                // });
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/clear.png")} alt="logo" />
              <div className="sideLabel">Clear Layouts</div>
            </IconButton>
          </div>
        </div>
        <div style={{ width: "90%" }}>
          <div
            className="preview-topbar"
            // style={{ width: "100%", height: "50px", background: "#2162D51A", display:"flex", alignItems: 'center', justifyContent:"end" }}
          >
            <ul
              style={{ display: "flex" }}
              className="parent-navigation middle-pannel-total-slides"
            >
              <li>
                <img
                  src={NewSlideLeftArrowIcon}
                  className={
                    !props.fileDetails?.previousId
                      ? "disableNavigate"
                      : "new-slide-left-icon"
                  }
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={goPrevTab}
                />
              </li>
              <div className="htmlPreview-pageCount">
                <span className="page-text pageTotalCount">
                  {props.fileDetails?.pageNumber}
                </span>
                <span className="page-text"> / </span>
                <span className="page-text slideTotalCount">{totalPages}</span>
              </div>
              <li>
                <img
                  src={NewSlideRightArrowIcon}
                  className={
                    !props.fileDetails?.nextId
                      ? "disableNavigate"
                      : "new-slide-right-icon"
                  }
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={goNextTab}
                />
              </li>
            </ul>
            {!props.arrayOfBoxesDrawn?.length || disableGenerateLayout() ? (
              <Tooltip title="Fetch all layouts to enable me!">
                <span>
                  <Button
                    className="generate-layoutBtn"
                    style={{ background: "white" }}
                    variant="outlined"
                    disabled
                    onClick={() => {
                      scrollTop();
                      props.setIsGJSLoading(true);
                      setTimeout(() => {
                        generateSection();
                        props.setGenerateLayout(true);
                        props.genLayoutRef.current = true;
                        props.setGenLayoutClicked(true);
                      }, 500);
                    }}
                  >
                    Generate Layout
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                className="generate-layoutBtn"
                style={{ background: "white" }}
                variant="outlined"
                onClick={() => {
                  scrollTop();
                  props.setIsGJSLoading(true);
                  setTimeout(() => {
                    generateSection();
                    props.setGenerateLayout(true);
                    props.genLayoutRef.current = true;
                    props.setGenLayoutClicked(true);
                  }, 500);
                }}
              >
                Generate Layout
              </Button>
            )}
          </div>
          {props.html ? (
            // <embed width="100%" height="100%"  src={file} />

            drawLayout &&
            fileContext.file.application_type !== APPLICATION_TYPE_EDETAIL ? (
              <div
                className="mainCanvasContainerWrapper custonScrollBar"
                id="mainCanvasContainerWrapper"
                onScroll={onScroll}
                ref={myContainer}
                onMouseDown={(e) => {
                  hideMenu();
                  e.detail < 2 &&
                    drawDiv(
                      e,
                      showSelection,
                      Xstart,
                      Ystart,
                      setMultiSelect,
                      multiSelect,
                      props.arrayOfBoxesDrawn,
                      props.setArrayOfBoxesDrawn,
                      props.arrayOfBoxesDrawnRef,
                      showMenu
                    );
                }}
                onMouseMove={(e) => {
                  mousedrag(
                    e,
                    showSelection,
                    Xstart,
                    Ystart,
                    setMultiSelect,
                    setMultiSelectedComps,
                    multiSelect
                  );
                }}
              >
                <div
                  id={"mainCanvasContainer "}
                  className="mainCanvasContainer "
                  // onScroll={onScroll}
                  onClick={() => {
                    hideMenu();
                  }}
                  style={{ userSelect: "none", display: "flex", width: "100%" }}
                >
                  <div
                    id={"drawSelectionDiv"}
                    style={{
                      width: multiSelect.width + "px",
                      height: multiSelect.height + "px",
                      left: multiSelect.left + "px",
                      top: multiSelect.top + "px",
                      position: "absolute",
                      zIndex: 100,
                      outline: "1px dashed black",
                    }}
                  ></div>
                  {props.fileDetails?.file_conversion_pages[0]
                    ?.html_file_path ? (
                    <div
                      ref={containerRef}
                      dangerouslySetInnerHTML={{ __html: props.html }}
                    ></div>
                  ) : (
                    <div className="static-message-html">
                      <h3>
                        HTML is not generated for this page. Please click{" "}
                        <span className="navigate-back" onClick={goBack}>
                          here
                        </span>{" "}
                        to back and generate.
                      </h3>
                    </div>
                  )}

                  {props?.arrayOfBoxesDrawn?.map((box, index) => {
                    if (box?.outline?.includes("#ef9a9a"))
                      if (index !== 0) sectionLK++;
                    return (
                      <Tooltip
                        title={
                          box?.outline?.includes("#ef9a9a")
                            ? `SECTION ${sectionLK}`
                            : box?.outline?.includes("#24f52e")
                            ? "COLUMN"
                            : box.blocktype || "blocktype"
                        }
                      >
                        <div
                          className="test 719"
                          name={"section" + index}
                          style={box}
                          originalboxwidth={box.originalBoxWidth}
                          blocktype={box.blocktype}
                        ></div>
                      </Tooltip>
                    );
                  })}
                  {menuVisible && (
                    <div
                      style={{
                        position: "absolute",
                        top: menuPosition.top,
                        left:
                          menuPosition.left < 100
                            ? menuPosition.left
                            : menuPosition.left - 100,
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        zIndex: 1000,
                      }}
                      className="modify-image-contextMenu"
                    >
                      <ul>
                        <li onClick={() => addType("SECTION", "drawn")}>
                          Outer Section
                        </li>

                        <li onClick={() => addType("COLUMN", "drawn")}>
                          Column
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="mainCanvasContainerWrapper custonScrollBar"
                id="mainCanvasContainerWrapper"
                onScroll={onScroll}
                ref={myContainer}
                onContextMenu={(e) => {
                  // if (selectedDiv === -1) return;
                  e.preventDefault();
                  showMenu(e, "onBoxClick");
                }}
              >
                <div
                  id={"mainCanvasContainer"}
                  // onScroll={onScroll}
                  className="mainCanvasContainer"
                >
                  {" "}
                  {props.fileDetails?.file_conversion_pages[0]
                    ?.html_file_path ? (
                    <div
                      ref={containerRef}
                      dangerouslySetInnerHTML={{ __html: props.html }}
                      style={{
                        zIndex: !drawLayout && !dragOrResize ? 999 : -1,
                        position:
                          !drawLayout && !dragOrResize ? "inherit" : "static",
                      }}
                      onDragStart={(e) => {
                        e.dataTransfer.setData("target", e.target);
                      }}
                    ></div>
                  ) : (
                    <div className="static-message-html">
                      <h3>
                        HTML is not generated for this page. Please click{" "}
                        <span className="navigate-back" onClick={goBack}>
                          here
                        </span>{" "}
                        to back and generate.
                      </h3>
                    </div>
                  )}
                  {props?.arrayOfBoxesDrawn?.map((obj, index) => (
                    <>
                      {selectedDiv !== index ? (
                        <div
                          ref={layoutBoxRef}
                          className="test"
                          name={"section" + index}
                          style={{ ...obj }}
                          onClick={() => {
                            setSelectedDiv(index);
                          }}
                        ></div>
                      ) : dragOrResize ? (
                        <Rnd
                          key={index}
                          style={{
                            zIndex: obj.zIndex, // Set your desired z-index value
                            /* Other styles for the Rnd component */
                          }}
                          default={{
                            x: parseFloat(obj.left),

                            y: parseFloat(obj.top),
                            width: obj.width,
                            height: obj.height,
                          }}
                          bounds="parent"
                          resizeHandleStyles={{
                            bottomRight: {
                              backgroundColor: "blue",
                              width: "4px",
                              height: "4px",
                              right: "-3px",
                              bottom: "-3px",
                            },
                            bottomLeft: {
                              backgroundColor: "blue",
                              width: "4px",
                              height: "4px",
                              left: "-3px",
                              bottom: "-3px",
                            },
                            topRight: {
                              backgroundColor: "blue",
                              width: "4px",
                              height: "4px",
                              right: "-3px",
                              top: "-3px",
                            },
                            topLeft: {
                              backgroundColor: "blue",
                              width: "4px",
                              height: "4px",
                              left: "-3px",
                              top: "-3px",
                            },
                          }}
                          onResizeStop={(
                            e,
                            direction,
                            ref,
                            delta,
                            position
                          ) => {
                            updateResizedDivs({
                              index: index,
                              width: ref.offsetWidth,
                              height: ref.offsetHeight,
                              origin: "resizeStop",
                              left: position.x,
                              top: position.y,
                            });
                          }}
                          onDragStop={(e, direction, ref, delta, position) => {
                            updateResizedDivs({
                              index: index,
                              ...direction,
                              origin: "dragStop",
                            });
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              outline: "1px dashed blue",
                            }}
                          ></div>
                        </Rnd>
                      ) : (
                        <div
                          ref={layoutBoxRef}
                          style={{ ...obj, outline: "1px dashed #ef9a9a" }}
                          onClick={() => {
                            // console.log("clicked setSelectedDiv 824");
                            setSelectedDiv(index);
                            // selectUnderlingCOntent();
                          }}
                        ></div>
                      )}
                    </>
                  ))}
                  {menuVisible && (
                    <div
                      style={{
                        position: "absolute",
                        top: menuPosition.top,
                        left: menuPosition.left,
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        zIndex: 1000,
                      }}
                      className="modify-image-contextMenu"
                    >
                      <ul>
                        <li onClick={() => addType("SECTION")}>
                          Outer Section
                        </li>
                        <li onClick={() => addType("COLUMN")}>Column</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )
          ) : null}

          <Dialog
            className="threshold"
            open={isThresholdVisible}
            onClose={handleClose}
          >
            <DialogTitle>Please Enter Threshold Value</DialogTitle>
            <DialogContent>
              <form onSubmit={getLayout}>
                <input
                  type="number"
                  value={thresholdValue}
                  onChange={handleThresholdInput}
                />
                {thresholdErrorMessage ? <p>{thresholdErrorMessage}</p> : null}
              </form>
            </DialogContent>
            <DialogActions>
              <Button disabled={!thresholdValue} onClick={getLayout}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <WarningPromptModal
            openWarnModal={openWarnModal}
            setOpenWarnModal={setOpenWarnModal}
            getInnerLayouts={getInnerLayouts}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            className="action-dropdown"
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              value={10}
              disabled={
                fileContext.file.application_type ===
                  APPLICATION_TYPE_EDETAIL ||
                (getLayoutsClicked && !layoutsFetched.sections.length)
              }
              onClick={() => {
                if (getLayoutsClicked) {
                  fetchColumnLayouts();
                } else {
                  clearColumnLayouts();
                }
                setSelectedDiv(-1);
                handleCloseMenu();
              }}
              disableRipple
            >
              {getLayoutsClicked
                ? "Get Column layouts"
                : "Clear Column layouts"}
            </MenuItem>
            <MenuItem
              disabled={
                fileContext.file.application_type ===
                  APPLICATION_TYPE_EDETAIL ||
                (getLayoutsClicked && !layoutsFetched.sections.length)
              }
              value={20}
              onClick={() => {
                if (getLayoutsClicked) {
                  fetchInnerLayouts();
                } else {
                  clearInnerLayouts();
                }
                handleCloseMenu();
                setSelectedDiv(-1);
              }}
              disableRipple
            >
              {getLayoutsClicked ? "Get Inner layouts" : "Clear Inner layouts"}
            </MenuItem>

            {/* {props.applicationType == APPLICATION_TYPE_EDETAIL ? */}
            <MenuItem
              value={20}
              onClick={() => {
                if (getLayoutsClicked) {
                  // setIsThresholdVisible(true);
                  getLayout();
                } else {
                  clearAllLayouts();
                }
                setSelectedDiv(-1);
                handleCloseMenu();
              }}
              disableRipple
            >
              {getLayoutsClicked ? "Get all layouts" : "Clear all layouts"}
            </MenuItem>
            {/* :
            null
          } */}
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default HTMLPreview;
