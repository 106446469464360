import "./Root.scss";
import React, { useState, useRef, useEffect, useContext } from "react";
import HTMLPreview from "../HtmlPreview/HTMLPreview";
import GrapejsEditor from "../GrapejsEditor/GrapejsEditor";
import StepperComp from "../Stepper/Stepper";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import CircularProgress from "@mui/material/CircularProgress";
// import CircularProgress from "@material-ui/core/CircularProgress";
import CustomCircularProgress from "./../CustomCircularProgress";

import FileUpload from "../FileUpload/FileUpload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonHeader from "../FileUpload/ButtonHeader";
import {
  imageIntermediateSave,
  intermediateSave,
  processHTML,
  uploadFile,
} from "../../services/api";
import AddMetadata from "../AddMetadata/AddMetadata";
import FileContext from "../../context/FileContext";
import { ToastContainer, toast } from "react-toastify";
import ImageLayout from "../ImageLayout/ImageLayout";
import {
  STEPPER_STEP_MODIFY_HTML,
  STEPPER_STEP_MODIFY_IMAGE,
  STEPPER_STEP_UPLOADFILE,
  UPLOAD_IMAGE_PROGRESS_MESSAGE,
  UPLOAD_PDF_PROGRESS_MESSAGE,
} from "./constants";
import { DUMMY_IMAGE_FILE } from "../ImageLayout/dummy-response";

const steps = ["Upload File", "Modify Image", "Modify HTML", "Add Metadata"];

function Root() {
  const [arrayOfBoxesDrawn, setArrayOfBoxesDrawn] = useState([]);
  const [numberOfLayouts, setNumberOfLayouts] = useState(0);
  const arrayOfBoxesDrawnRef = useRef(arrayOfBoxesDrawn);
  const [activeStep, setActiveStep] = React.useState("0");
  const [prevStep, setPrevStep] = React.useState(0);
  const [file, setFile] = useState();
  const [uploadedFfileDetails, setUploadedFileDetails] = useState();
  const [imageFileDetails, setImageFileDetails] = useState();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isGJSLoading, setIsGJSLoading] = useState(true);

  // const [isExportTriggered, setIsExportTriggered] = useState(false);
  const [exportCount, setExportCount] = useState(0);
  const [publishHTML, setPublishHTML] = useState(0);
  const [userGeneratedHTML, setUserGeneratedHTML] = useState("");
  const [isLayoutGenerated, setIsLayoutGenerated] = useState(false);
  const [isImageLayoutGenerated, setIsImageLayoutGenerated] = useState(false);
  const [arrayOfBoxesDrawnImage, setarrayOfBoxesDrawnImage] = useState([]);

  const [generateLayout, setGenerateLayout] = useState(false);
  const genLayoutRef = useRef(generateLayout);
  const [genLayoutClicked, setGenLayoutClicked] = useState(false);
  const fileContext = useContext(FileContext);
  const [html, setHtml] = useState(null);
  const [fileUploadMessage, setFileUploadMessage] = useState(null);
  const htmlRef = useRef(html);
  const [data, setData] = useState("");
  const dataRef = useRef(data);
  const [rhsHtmlForExport, setRhsHtmlForExport] = useState("");
  const rhsHtmlForExportRef = useRef(rhsHtmlForExport);
  const [cooridinatesFromAPI, setCooridinatesFromAPI] = useState([]);
  const innerLayoutsRef = useRef(cooridinatesFromAPI);
  const [usrGenHtML, setUsrGenHtML] = useState();
  const usrGenHtMLRef = useRef(usrGenHtML);
  const [imageDimensions, setImageDimensions] = useState({
    originalWidth: "",
    originalHeight: "",
    modifiedWidth: "",
    modifiedHeight: "",
  });
  const [scaleFactorImage, setScaleFactorImage] = useState({
    toScaleDown: 1,
  });
  const [croppingBoxes, setCroppingBoxes] = useState([1]);
  const [modifiedCroppedImage, setModifiedCroppedImage] = useState({});
  const [downloadHTML, setDownloadHTML] = useState(false);
  const [responsiveHtml, setResponsiveHtml] = useState(false);
  const [isDownloadZip, setIsDownloadZip] = useState(false);
  const [clearAllLayoutClicked, setClearAllLayoutClicked] = useState(false);
  const [disableGenerateLayout, setDisableGenerateLayout] = useState(true);
  const [isBackDisabled, setIsBackDisabled] = useState(false);
  const [upscaledHTMLUsrGnrtdPath, setUpscaledHTMLUsrGnrtdPath] = useState("");
  const [isPublished, setIsPublished] = useState(false);

  const navigate = useNavigate();
  // console.log(arrayOfBoxesDrawn, "arrayOfBoxesDrawn");
  useEffect(() => {
    console.log("setActivestep");
    const storedStep = JSON.parse(window.localStorage.getItem("activeStep"));
    if (storedStep) {
      setActiveStep(storedStep);
    } else {
      setActiveStep(0);
    }
  }, []);

  useEffect(() => {
    if (fileContext.isLogoutClicked) {
      navigate("/dynamic-layout");
    }
  }, [fileContext.isLogoutClicked]);

  useEffect(() => {
    arrayOfBoxesDrawnRef.current = arrayOfBoxesDrawn;
    setNumberOfLayouts(arrayOfBoxesDrawn.length);
  }, [arrayOfBoxesDrawn]);

  useEffect(() => {
    //navigateIssue
    if (fileContext.file) {
      if (
        fileContext?.file?.file_conversion_pages?.[0]?.message?.step ===
        STEPPER_STEP_MODIFY_IMAGE
      ) {
        setImageFileDetails(fileContext.file);
        // setImageFileDetails(DUMMY_IMAGE_FILE);
      } else if (
        fileContext?.file?.file_conversion_pages[0]?.message?.step ===
        STEPPER_STEP_MODIFY_HTML
      ) {
        setUploadedFileDetails(fileContext.file);
      }
      console.log("setActivestep");

      setActiveStep(fileContext?.file?.file_conversion_pages[0].message?.step);
    }

    if (fileContext.imageFile) {
      //setcroppingboxes here
      setImageFileDetails(fileContext.imageFile);
    }
  }, [fileContext.file, fileContext.imageFile]);

  useEffect(() => {
    setGenerateLayout(genLayoutRef.current);
    setNumberOfLayouts(-11);
  }, [genLayoutRef.current]);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    window.localStorage.setItem("activeStep", activeStep);

    if (fileContext.file) {
      if (activeStep === STEPPER_STEP_MODIFY_IMAGE) {
        setImageFileDetails(fileContext.file);
        // setImageFileDetails(DUMMY_IMAGE_FILE);
      } else if (activeStep === STEPPER_STEP_MODIFY_HTML) {
        setUploadedFileDetails(fileContext.file);
      }
    }
  }, [activeStep]);

  const HtMLStringToDOMElementFromExport = (htmlString) => {
    // Create a DOMParser

    const parser = new DOMParser();

    // Parse the HTML string into a DOM element
    const doc = parser.parseFromString(htmlString, "text/html");
    // Extract the html element from the parsed document

    return doc.querySelector("html");
  };
  const cssStringToCSSStyles = (cssString) => {
    const cssBlocks = cssString
      .split("}")
      .filter((block) => block.trim() !== "");

    // Initialize an empty object to store the CSS styles
    const cssStyles = {};

    // Iterate through the CSS blocks and parse them into key-value pairs
    cssBlocks.forEach((block) => {
      // Split the block into selector and properties
      const [selector, properties] = block
        .split("{")
        .map((item) => item.trim());

      // Initialize an empty object for the selector
      const selectorStyles = {};

      // Split the properties into individual style declarations
      const styleDeclarations = properties
        .split(";")
        .filter((declaration) => declaration.trim() !== "");

      // Iterate through style declarations and parse them into key-value pairs
      styleDeclarations.forEach((declaration) => {
        const [property, value] = declaration
          .split(":")
          .map((item) => item.trim());
        selectorStyles[property] = value;
      });

      // Store the selector styles in the main CSS styles object
      cssStyles[selector] = selectorStyles;
    });
    return cssStyles;
  };
  const replaceIdsWithStyles = (divElement, cssStyles) => {
    // const deepaDiv = document.getElementById("deepa");
    // const currentPadding = deepaDiv.style.padding;
    // deepaDiv.removeAttribute("id");
    // deepaDiv.setAttribute("style", `padding:${currentPadding}`);

    for (let prop in cssStyles) {
      if (prop.startsWith("#")) {
        let currentDiv = divElement.querySelector(prop);
        const styleObj = cssStyles[prop];
        if (fileContext.file.application_type === 2 || prop !== "#wrapper")
          currentDiv.removeAttribute("id");
        let newStr = "";
        for (let prop in styleObj) {
          newStr = newStr + prop + ":" + styleObj[prop] + ";";
        }
        currentDiv.setAttribute("style", newStr);
      }
    }
  };

  function ObjectOfObjectsToArrayOfObjects() {
    // imageFileDetails.cropped_image_data;
    //croppingBoxes;
    let inputString =
      imageFileDetails.file_conversion_pages[0].cropped_image_data
        .cropped_image_1.imageURL;
    let desiredString = "";
    if (inputString) {
      let lastSlashIndex =
        imageFileDetails.file_conversion_pages[0].cropped_image_data.cropped_image_1?.imageURL.lastIndexOf(
          "/"
        );
      desiredString = inputString.substring(0, lastSlashIndex + 1);
    }
    // croppingBoxes.forEach((box, index) => {
    //   let bottom = box?.bottom;
    //   let top = box?.top;

    //   let rhsObj = {
    //     height_end: parseFloat(bottom) / scaleFactorImage.toScaleDown,
    //     height_start: parseFloat(top) / scaleFactorImage.toScaleDown,
    //     width: imageDimensions.originalWidth,
    //     imageURL: desiredString + "cropped_image_" + (index + 1) + ".jpg",
    //   };
    // });

    let newObject = {};

    croppingBoxes.forEach((box, index) => {
      let key = `cropped_image_${index + 1}`;
      newObject[key] = {
        height_end: parseFloat(box.bottom) / scaleFactorImage.toScaleDown,
        height_start: parseFloat(box.top) / scaleFactorImage.toScaleDown,
        width: imageDimensions.originalWidth,
        imageURL: desiredString
          ? desiredString + "cropped_image_" + (index + 1) + ".jpg"
          : undefined,
      };
    });
    return newObject;
  }
  const customDrag = (e, j) => {
    let data = JSON.stringify(e.target);
    e.dataTransfer.setData("text", `${e.target.outerHTML}`);

    setData(e.target.getAttribute("name"));
  };

  const onFileUpload = (file) => {
    setFile(file);
  };

  const handleNext = () => {
    if (activeStep === STEPPER_STEP_UPLOADFILE) {
      setIsFileUploading(true);
    }
    setIsGJSLoading(true);
    setTimeout(() => {
      setPrevStep(activeStep);
      if (activeStep === STEPPER_STEP_UPLOADFILE) {
        uploadFile(file)
          .then((res) => {
            // setFileUploadMessage("Successfully Uploaded file");
            toast.success("Successfully Uploaded file");
            setIsFileUploading(false);
            // setTimeout(() => {
            //   setFileUploadMessage(null);
            console.log("setActivestep");

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setImageFileDetails(res.data);
            fileContext.selectImageFile(res.data);
            // let tempArray = [];
            // for (let x in res.data.cropped_image_data) {
            //   res.data.cropped_image_data[x].index = x;
            //   tempArray.push(res.data.cropped_image_data[x]);
            // }
            // setCroppingBoxes(tempArray);
          })
          .catch((err) => {
            let errorMessage = "Something went wrong! Please try again.";

            if (err?.response?.data?.message) {
              errorMessage = err?.response?.data?.message;
            }

            toast.error(errorMessage);
            setIsFileUploading(false);
            // setTimeout(() => {
            //   setFileUploadMessage(null);
            // }, 2000);
          });
      } else if (activeStep === STEPPER_STEP_MODIFY_IMAGE) {
        let newArray = [];
        let scaleFactor = 1;

        if (imageDimensions.modifiedWidth) {
          scaleFactor =
            imageDimensions.modifiedWidth / imageDimensions.originalWidth;
        }
        //   setScaleFactorImage({ ...scaleFactorImage, toScaleUp: scaleFactor });
        // }

        arrayOfBoxesDrawnImage.forEach((element) =>
          newArray.push({
            x_1: parseFloat(element.left) / scaleFactor,
            y_1: parseFloat(element.top) / scaleFactor,
            x_2: parseFloat(element.right) / scaleFactor,
            y_2: parseFloat(element.bottom) / scaleFactor,
            width: element.width,
            height: element.height,
            zIndex: element.zIndex,
            scaleFactorToScaleDown: scaleFactor,
            blocktype: element.blocktype || "LAYOUT_DEFAULT",
          })
        );
        let body = {
          bbox_json: newArray,
          image_file_path:
            imageFileDetails?.file_conversion_pages[0].image_path,
          generatedUUID: imageFileDetails?.file_conversion_pages[0].page_uuid,
          file_name:
            imageFileDetails.file_name ||
            imageFileDetails?.pdf_file_path?.split("/").pop(),
          // file_name: "MBI-34596_Poland_Jardiance_Email_Sample16 1.pdf",
          id: imageFileDetails?.file_conversion_pages[0]?.id,
          parent_id: imageFileDetails?.file_conversion_pages[0]?.conversion_id,
          cropped_image_data: Object.values(modifiedCroppedImage).length
            ? modifiedCroppedImage
            : ObjectOfObjectsToArrayOfObjects(
                imageFileDetails?.file_conversion_pages[0].cropped_image_data,
                {}
              ),
          application_type: imageFileDetails?.application_type,
          pdf_file_path: imageFileDetails?.pdf_file_path,
        };
        setIsFileUploading(true);
        processHTML(body)
          .then((resp) => {
            window.localStorage.removeItem("gjsProject");
            fileContext.selectFile(resp.data);
            console.log("setActivestep");

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            toast.success("HTML loaded successfully!");
            setIsFileUploading(false);
            setUploadedFileDetails(resp.data);
            setArrayOfBoxesDrawn([]);
          })
          .catch((err) => {
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            toast.error("Failed to load HTML!");
            setIsFileUploading(false);
            setIsGJSLoading(false);
          });
        // pocessNewImageDimensions()
        //   .then((resp) => {
        //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //     toast.success("HTML loaded successfully!");
        //     setIsFileUploading(false);
        //     setUploadedFileDetails(resp.data[0]);
        //   })
        //   .catch((err) => {
        //     toast.error("Failed to load HTML!");
        //     setIsFileUploading(false);
        //   });
      } else if (activeStep == STEPPER_STEP_MODIFY_HTML) {
        setTimeout(() => {
          setExportCount(exportCount + 1);
        }, 100);
        // setTimeout(() => {
        //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // }, 400);
        // setIsExportTriggered(false);
      }
    }, 1000);
  };

  const exportClicked = () => {
    setPublishHTML(publishHTML + 1);
  };
  const downloadClicked = (flag) => {
    setDownloadHTML(flag);
  };
  const downloadResponsiveHTML = (flag) => {
    setResponsiveHtml(flag);
  };

  const downloadZip = (flag) => {
    setIsDownloadZip(flag);
  };
  const getUserGeneratedHTML = (userGeneratedHTML) => {
    setUserGeneratedHTML(userGeneratedHTML);
  };

  const handleBack = () => {
    console.log("setActivestep");

    setPrevStep(activeStep);
    setExportCount(0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const modifyImageIntermediateSave = () => {
    if (!arrayOfBoxesDrawnImage?.length) return;

    const body = {
      bbox_json: arrayOfBoxesDrawnImage,
      image_file_path: imageFileDetails?.file_conversion_pages[0]?.image_path,
      generatedUUID: imageFileDetails?.file_conversion_pages[0].page_uuid,
      file_name: imageFileDetails?.pdf_file_path?.split("/").pop(),
      id: imageFileDetails?.file_conversion_pages[0].id,
      parent_id: imageFileDetails?.file_conversion_pages[0]?.conversion_id,
      cropped_image_data: Object.values(modifiedCroppedImage).length
        ? modifiedCroppedImage
        : ObjectOfObjectsToArrayOfObjects(
            imageFileDetails?.file_conversion_pages[0].cropped_image_data,
            {}
          ),
    };

    imageIntermediateSave(body)
      .then((res) => {})
      .catch((err) => {});
  };
  const HtMLStringToDOMElement = (htmlString) => {
    // Create a DOMParser

    const parser = new DOMParser();

    // Parse the HTML string into a DOM element
    const doc = parser.parseFromString(htmlString, "text/html");
    // Extract the html element from the parsed document

    return doc.querySelector("html");
  };
  const upScaleImageAndFontSize = (htmlResp) => {
    let htmlDOMEl = HtMLStringToDOMElement(htmlResp);
    let currentWidth = 600;
    let desiredWidth = 1024;
    var scaleFactor = desiredWidth / currentWidth;
    let wrapperDiv = htmlDOMEl.childNodes[1].childNodes[0];
    for (let k = 0; k < wrapperDiv.childNodes.length; k++) {
      let sectionDiv = wrapperDiv.childNodes[k];

      if (sectionDiv && parseFloat(sectionDiv.style.width) !== 1024) {
        var currentsectionHeight = parseFloat(sectionDiv.style.height);
        var currentsectionWidth = parseFloat(sectionDiv.style.width);

        var currentsectionTop = parseFloat(sectionDiv.style.top);
        var currentsectionLeft = parseFloat(sectionDiv.style.left);

        var desiredHeight = currentsectionHeight * scaleFactor;
        var desiredWidth1 = currentsectionWidth * scaleFactor;
        let desiredTop = currentsectionTop * scaleFactor;
        let desiredLeft = currentsectionLeft * scaleFactor;

        sectionDiv.style.width = desiredWidth1 + "px";
        sectionDiv.style.height = desiredHeight + "px";
        sectionDiv.style.top = desiredTop + "px";
        sectionDiv.style.left = desiredLeft + "px";

        var elementsWithPxValues =
          sectionDiv.querySelectorAll('*[style*="px"]');
        for (var i = 0; i < elementsWithPxValues.length; i++) {
          var element = elementsWithPxValues[i];
          console.log("element.tagName", element.tagName);
          var newRelativeValueFont =
            parseFloat(element.style.fontSize) * scaleFactor;
          var newRelativeValueTop = parseFloat(element.style.top) * scaleFactor;
          var newRelativeValueLeft =
            parseFloat(element.style.left) * scaleFactor;

          // Update the element with the new value
          element.style.fontSize = newRelativeValueFont + "px";
          element.style.top = newRelativeValueTop + "px";
          element.style.left = newRelativeValueLeft + "px";
          // if (element.parentElement.tagName === "TD") {
          //   ;
          // element.style.top = newRelativeValueTop + 15 + "px";
          // }

          if (
            element.tagName === "TABLE" ||
            element.tagName === "TD" ||
            element.tagName === "TH"
          ) {
            let tableHeightAdjust =
              (parseFloat(element.style.height) * 8) / 123;
            console.log(
              tableHeightAdjust,
              element.tagName,
              "tableHeightAdjust "
            );
            element.style.width =
              parseFloat(element.style.width) * scaleFactor + "px";
            element.style.height =
              (parseFloat(element.style.height) - 8) * scaleFactor + "px";
          }
          if (element.tagName === "IMG" || element.tagName === "A") {
            //  console.log(element.style.width * scaleFactor, "newWidth");
            element.style.width =
              parseFloat(element.style.width) * scaleFactor + "px";
            // element.setAttribute("onload", () => onEachIageLoad(divs - i));
          }
        }
      }
    }
    return htmlDOMEl.innerHTML;
  };
  const renderStep = () => {
    let renderContent = "";

    switch (activeStep) {
      case 0:
        renderContent = (
          <>
            {!isFileUploading ? (
              <>
                <FileUpload onFileUpload={onFileUpload} />
                <Dialog open={fileUploadMessage}>
                  <DialogTitle>{fileUploadMessage}</DialogTitle>
                </Dialog>
              </>
            ) : (
              <div className="file-upload-loader">
                <lottie-player
                  src={JSON.stringify(require("../../Images/97203.json"))}
                  background="transparent"
                  speed="1"
                  style={{ width: "226px", height: "127px" }}
                  loop
                  autoplay
                ></lottie-player>
                <span>Converting PDF to HTML. This may take a while </span>
              </div>
            )}
          </>
        );
        break;

      case 1:
        renderContent = (
          <ImageLayout
            fileDetails={imageFileDetails}
            arrayOfBoxesDrawnImage={arrayOfBoxesDrawnImage}
            setarrayOfBoxesDrawnImage={setarrayOfBoxesDrawnImage}
            imageFileDetails={imageFileDetails}
            imageDimensions={imageDimensions}
            setImageDimensions={setImageDimensions}
            scaleFactorImage={scaleFactorImage}
            setScaleFactorImage={setScaleFactorImage}
            croppingBoxes={croppingBoxes}
            setCroppingBoxes={setCroppingBoxes}
            modifiedCroppedImage={modifiedCroppedImage}
            setModifiedCroppedImage={setModifiedCroppedImage}
            ObjectOfObjectsToArrayOfObjects={ObjectOfObjectsToArrayOfObjects}
            setIsGJSLoading={setIsGJSLoading}
            setImageFileDetails={setImageFileDetails}
            setIsPublished={setIsPublished}
          />
        );
        break;
      case 2:
        renderContent = (
          <>
            <HTMLPreview
              arrayOfBoxesDrawn={arrayOfBoxesDrawn}
              setArrayOfBoxesDrawn={setArrayOfBoxesDrawn}
              arrayOfBoxesDrawnRef={arrayOfBoxesDrawnRef}
              setGenerateLayout={setGenerateLayout}
              genLayoutRef={genLayoutRef}
              file={file}
              fileDetails={uploadedFfileDetails}
              setUploadedFileDetails={setUploadedFileDetails}
              html={html}
              setHtml={setHtml}
              htmlRef={htmlRef}
              rhsHtmlForExport={rhsHtmlForExport}
              setRhsHtmlForExport={setRhsHtmlForExport}
              rhsHtmlForExportRef={rhsHtmlForExportRef}
              prevStep={prevStep}
              customDrag={customDrag}
              genLayoutClicked={genLayoutClicked}
              setGenLayoutClicked={setGenLayoutClicked}
              cooridinatesFromAPI={cooridinatesFromAPI}
              setCooridinatesFromAPI={setCooridinatesFromAPI}
              innerLayoutsRef={innerLayoutsRef}
              arrayOfBoxesDrawnImage={arrayOfBoxesDrawnImage}
              imageDimensions={imageDimensions}
              isGJSLoading={isGJSLoading}
              setIsGJSLoading={setIsGJSLoading}
              setIsLayoutGenerated={setIsLayoutGenerated}
              setClearAllLayoutClicked={setClearAllLayoutClicked}
              clearAllLayoutClicked={clearAllLayoutClicked}
              disableGenerateLayout={disableGenerateLayout}
              setDisableGenerateLayout={setDisableGenerateLayout}
              usrGenHtMLRef={usrGenHtMLRef}
              setActiveStep={setActiveStep}
              HtMLStringToDOMElementFromExport={
                HtMLStringToDOMElementFromExport
              }
              cssStringToCSSStyles={cssStringToCSSStyles}
              replaceIdsWithStyles={replaceIdsWithStyles}
              setIsPublished={setIsPublished}
            />
            {/* <div id="blocks"></div> */}
            <GrapejsEditor
              arrayOfBoxesDrawnRef={arrayOfBoxesDrawnRef}
              setArrayOfBoxesDrawn={setArrayOfBoxesDrawn}
              genLayoutRef={genLayoutRef}
              exportCount={exportCount}
              activeStep={activeStep}
              getUserGeneratedHTML={getUserGeneratedHTML}
              htmlRef={htmlRef}
              setActiveStep={setActiveStep}
              fileDetails={uploadedFfileDetails}
              rhsHtmlForExport={rhsHtmlForExport}
              setRhsHtmlForExport={setRhsHtmlForExport}
              rhsHtmlForExportRef={rhsHtmlForExportRef}
              prevStep={prevStep}
              genLayoutClicked={genLayoutClicked}
              setGenLayoutClicked={setGenLayoutClicked}
              dataRef={dataRef}
              setData={setData}
              setCooridinatesFromAPI={setCooridinatesFromAPI}
              innerLayoutsRef={innerLayoutsRef}
              setIsLayoutGenerated={setIsLayoutGenerated}
              isGJSLoading={isGJSLoading}
              setIsGJSLoading={setIsGJSLoading}
              downloadHTML={downloadHTML}
              downloadClicked={downloadClicked}
              clearAllLayoutClicked={clearAllLayoutClicked}
              setClearAllLayoutClicked={setClearAllLayoutClicked}
              usrGenHtMLRef={usrGenHtMLRef}
              upScaleImageAndFontSize={upScaleImageAndFontSize}
              setUpscaledHTMLUsrGnrtdPath={setUpscaledHTMLUsrGnrtdPath}
              setUploadedFileDetails={setUploadedFileDetails}
              HtMLStringToDOMElementFromExport={
                HtMLStringToDOMElementFromExport
              }
              cssStringToCSSStyles={cssStringToCSSStyles}
              replaceIdsWithStyles={replaceIdsWithStyles}
              isPublished={isPublished}
              userGeneratedHTML={userGeneratedHTML}
            />
          </>
        );

        break;
      case 3:
        renderContent = (
          <AddMetadata
            publishHTML={publishHTML}
            userGeneratedHTML={userGeneratedHTML}
            fileDetails={uploadedFfileDetails}
            downloadHTML={downloadHTML}
            downloadClicked={downloadClicked}
            downloadResponsiveHTML={downloadResponsiveHTML}
            responsiveHtml={responsiveHtml}
            setIsGJSLoading={setIsGJSLoading}
            setIsBackDisabled={setIsBackDisabled}
            isDownloadZip={isDownloadZip}
            downloadZip={setIsDownloadZip}
            upScaleImageAndFontSize={upScaleImageAndFontSize}
            upscaledHTMLUsrGnrtdPath={upscaledHTMLUsrGnrtdPath}
            isPublished={isPublished}
          />
        );
        break;

      default:
        renderContent = renderContent;
        break;
    }
    return renderContent;
  };
  if (isFileUploading) {
    let progressMessage = "";
    if (activeStep === STEPPER_STEP_UPLOADFILE) {
      progressMessage = UPLOAD_PDF_PROGRESS_MESSAGE;
    } else if (activeStep === STEPPER_STEP_MODIFY_IMAGE) {
      progressMessage = UPLOAD_IMAGE_PROGRESS_MESSAGE;
    }
    return (
      <div className="file-upload-loader">
        <lottie-player
          src={JSON.stringify(require("../../Images/97203.json"))}
          background="transparent"
          speed="1"
          style={{ width: "226px", height: "127px" }}
          loop
          autoplay
        ></lottie-player>
        <span> {progressMessage} </span>
      </div>
    );
  }
  return (
    <>
      <div className="Root">
        {isGJSLoading && activeStep !== 0 && (
          <CustomCircularProgress color="inherit" />
        )}
        {/* <ToastContainer /> */}
        <ButtonHeader
          exportClicked={exportClicked}
          handleBack={handleBack}
          currentStep={activeStep}
          isValidFileName={file?.isValidFileName}
          nextClick={handleNext}
          isProcessing={isFileUploading}
          isLayoutGenerated={isLayoutGenerated}
          isImageLayoutGenerated={arrayOfBoxesDrawnImage?.length}
          modifyImageCancelHandler={modifyImageIntermediateSave}
          setIsGJSLoading={setIsGJSLoading}
          downloadClicked={downloadClicked}
          downloadResponsiveHTML={downloadResponsiveHTML}
          croppingBoxes={croppingBoxes}
          disableGenerateLayout={disableGenerateLayout}
          isBackDisabled={isBackDisabled}
          fileDetails={uploadedFfileDetails}
          setIsDownloadZip={setIsDownloadZip}
          isPublished={isPublished}
        />
        <div className="stepper">
          <StepperComp steps={steps} activeStep={activeStep} />
        </div>
        <div className="container">{renderStep()}</div>
      </div>
    </>
  );
}

export default Root;
